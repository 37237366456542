/* eslint-disable */

import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { getAllFileNamesFromLibrary } from 'services/admin';
import { S3_BUCKET_BASE_URL as base } from 'services/common';
import useFallbackImage from 'hooks/useFallbackImage';
import imagelibrary from 'assets/icons/imagelibrary.svg';

const LibraryPickerStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(0, 0, 0, 0.3);
  z-index: 10;

  & > .button--x {
    background: rgb(255, 255, 255, 0.3);
    font-size: 20px;
    position: absolute;
    z-index: 12;
    top: 10px;
    right: 10px;
    color: #fff;
  }

  & > .library-picker__container {
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: 50px auto;
  }

  & .library-picker {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    background-color: #fff;
    width: 100%;
    margin-left: 
    z-index: 11;
  }
  
  & .library-picker__search {
    width: 100%;
    height: 50px;
    background-color: white;
  }

  & .library-picker__image {
    position: relative;
    width: 24%;
    margin: 5px;
    height: 300px;
    cursor: pointer;
    z-index: 12;
  }

  & .library-picker__image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  & .library-picker__image__name {
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: rgb(255, 255, 255, 0.3);
    font-size: 20px;
    text-align: center;
  }
`;

const LibraryActivateStyled = styled.button`
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  background-color: #2b78fe;
  align-items: center;

  & > img {
    height: 16px;
    width: 16px;
  }
`;

const LibraryPicker = ({ pickedCallback }) => {
  const [allLibraryImages, setAllLibraryImages] = useState([]);
  const [isLibraryPickerActive, setIsLibraryPickerActive] = useState(false);
  const [imageSearch, setImageSearch] = useState('');

  const { setFallbackImageSource } = useFallbackImage('');

  const setPickedImage = image => {
    setIsLibraryPickerActive(false);
    pickedCallback(image);
  };

  useEffect(() => {
    getAllFileNamesFromLibrary().then(({ data }) => {
      setAllLibraryImages(data);
    });
  }, []);

  if (!isLibraryPickerActive)
    return (
      <LibraryActivateStyled className="button-admin" onClick={() => setIsLibraryPickerActive(true)}>
        <img src={imagelibrary} alt="Library icon" />
        <span>Choose from library</span>
      </LibraryActivateStyled>
    );

  return (
    <LibraryPickerStyled>
      <button className="button-admin button--x" onClick={() => setIsLibraryPickerActive(false)}>
        X
      </button>
      <div className="library-picker__container">
        <input
          type="text"
          className="input library-picker__search"
          placeholder="Enter your image search here"
          value={imageSearch}
          onChange={e => setImageSearch(e.target.value)}
        />
        <div className="library-picker">
          {allLibraryImages.filter(image => image.includes(imageSearch)).map((image, index) => (
            <div key={index} className="library-picker__image" onClick={() => setPickedImage(image)}>
              <img src={`${base}/images/library/${image}`} alt={image} onError={setFallbackImageSource}/>
              <div className="library-picker__image__name">{image}</div>
            </div>
          ))}
        </div>
      </div>
    </LibraryPickerStyled>
  );
};

export default LibraryPicker;
