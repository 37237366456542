import eventsVisual from 'assets/visuals/visual-events.png';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import AnimatedStack, { getAnimation } from 'components/styled/AnimatedStack';
import Flex from 'components/styled/Flex';
import TextUnderline from 'components/styled/TextUnderline';
import useFallbackImage from 'hooks/useFallbackImage';
import { mediaLessThan, useWindowSize } from 'utils/media';
import { normalizeForUrl } from 'utils/normalizeForUrl';
import { addSeparators } from 'utils/number';

import { postConfigurationWithValue } from 'services/configuration';
import { S3_BUCKET_BASE_URL as base } from '../../services/common';
import { getLatestEventImages } from '../../services/event';

const EventsDescriptionFlex = styled(Flex)`
  margin-top: 150px;
  max-width: 350px;
`;

const EventsStack = styled(AnimatedStack)`
  bottom: 70px;
  left: -180px;

  @media screen and (max-width: 900px) {
    bottom: 50px;
  }
`;

const EventPoster = ({ image, alt, href }) => {
  const { setFallbackImageSource } = useFallbackImage('');
  return (
    <Link rel="noopener" to={href}>
      <img className="landing__event__poster" src={image} alt={alt} onError={setFallbackImageSource} />
    </Link>
  );
};

const EventsInfo = () => {
  const [latestEvents, setLatestEvents] = useState(null);
  const [numberConfigurations, setNumberConfigurations] = useState();
  const [numberOfLectures, setNumberOfLectures] = useState('');
  const [numberOfAttendees, setNumberOfAttendees] = useState('');
  useWindowSize();

  useEffect(() => {
    getLatestEventImages({ numberOfEvents: 4 }).then(response => setLatestEvents(response.data));
    postConfigurationWithValue({
      key: 'from-list',
      keys: ['NumberOfAttendees', 'NumberOfLectures'],
    })
      .then(res => {
        setNumberConfigurations(res && res.data);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    if (numberConfigurations) {
      checkIfNullOrUndefined(numberConfigurations['numberOfLectures']) &&
        setNumberOfLectures(numberConfigurations['numberOfLectures'].value);
      checkIfNullOrUndefined(numberConfigurations['numberOfAttendees']) &&
        setNumberOfAttendees(numberConfigurations['numberOfAttendees'].value);
    }
  }, [numberConfigurations]);

  const checkIfNullOrUndefined = itemToCheck => {
    return itemToCheck !== null && itemToCheck !== undefined;
  };

  return (
    <div className="landing__events">
      <EventsDescriptionFlex direction="column">
        <p className="heading--tiny">Događaji</p>
        <h2 className="heading--normal landing__events__heading">
          Organiziramo&nbsp;
          <TextUnderline color="#5b6cc2" underlineColor="transparent">
            cool
          </TextUnderline>
          događaje.
        </h2>
        <p className="text__description">
          Naša Udruga pokrenuta je zbog entuzijazma i ljubavi prema tehnologiji. Sva svoja znanja i vještine već
          godinama prenosimo organizacijom raznih predavanja i radionica te IT konferencije DUMP Days.
        </p>
        <Flex justifyContent="space-between" margin="48px 0 62px 0">
          {numberOfLectures && numberOfLectures !== '' && (
            <Flex direction="column">
              <h2 className="heading--normal marginless">{addSeparators(numberOfLectures)}+</h2>
              <p className="text__description marginless">predavanja</p>
            </Flex>
          )}
          {numberOfAttendees && numberOfAttendees !== '' && (
            <Flex direction="column">
              <h2 className="heading--normal marginless">{addSeparators(numberOfAttendees)}+</h2>
              <p className="text__description marginless">posjetitelja</p>
            </Flex>
          )}
        </Flex>
        <Link rel="noopener" to="/events">
          <button className="button--blue">Vidi događaje</button>
        </Link>
      </EventsDescriptionFlex>
      <div className="landing__events__displays">
        <img src={eventsVisual} alt="Events" />
        <div className="landing__events__camera">
          <div className="landing__events__dolly">
            <EventsStack
              animation={mediaLessThan(900) ? getAnimation(-290, -480 * 4) : getAnimation(10, -405 * 4)}
              duration="32.05"
              animateOnce
              offset="-1220"
            >
              {latestEvents &&
                latestEvents.map((event, index) => (
                  <EventPoster
                    key={index}
                    image={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`}
                    alt={`${event.name}`}
                    href={{
                      pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
                      state: { event },
                    }}
                  />
                ))}
            </EventsStack>
            <EventsStack
              animation={mediaLessThan(900) ? getAnimation(530, -275 * 4) : getAnimation(830, -200 * 4)}
              duration="32.05"
            >
              {latestEvents &&
                latestEvents.map((event, index) => (
                  <EventPoster
                    key={index}
                    image={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`}
                    alt={`${event.name} image`}
                    href={{
                      pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
                      state: { event },
                    }}
                  />
                ))}
            </EventsStack>
            <EventsStack
              animation={mediaLessThan(900) ? getAnimation(530, -275 * 4) : getAnimation(830, -200 * 4)}
              delay="15.95"
              offset="830"
              duration="32.05"
            >
              {latestEvents &&
                latestEvents.map((event, index) => (
                  <EventPoster
                    key={index}
                    image={`${base}/images/${event.posterImagePath.folder}/${event.posterImagePath.image}`}
                    alt={`${event.name} image`}
                    href={{
                      pathname: `/events/${event.id}-${normalizeForUrl(event.name)}`,
                      state: { event },
                    }}
                  />
                ))}
            </EventsStack>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsInfo;
