import React from 'react';
import ProjectSectionStyled from 'components/styled/ProjectSectionStyled';
import useFallbackImage from 'hooks/useFallbackImage';
import { S3_BUCKET_BASE_URL as base } from 'services/common';
import {
  getStyledComponentProps,
  hasImage,
  isImageGallery,
  isImageOnly,
  isSingleTextEditor,
  isTripleTextEditor,
} from 'services/projectSectionLogic';
import styled from 'styled-components';

import ImageLoader from 'components/common/ImageLoader';
import TextElement from 'components/common/TextElement';

const defaultText = [
  {
    type: 'paragraph',
    children: [{ text: '' }],
  },
];

export const getTextEditorValue = (projectSection, editorIndex) => {
  const filtered =
    projectSection &&
    projectSection.text &&
    !!projectSection.text.length &&
    !!projectSection.text.filter &&
    projectSection.text.filter(element => element.editorIndex === editorIndex);
  return projectSection && filtered.length !== 0 ? filtered : defaultText;
};

const Wrapper = styled.div`
  width: calc(100% - 460px);
  margin: auto;

  @media screen and (max-width: 1320px) {
    width: calc(100% - 280px);
  }

  @media screen and (max-width: 1260px) {
    width: calc(100% - 200px);
  }

  @media screen and (max-width: 1200px) {
    width: calc(100% - 120px);
  }

  @media screen and (max-width: 1320px) {
    width: calc(100% - 80px);
  }

  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

const ProjectDetailsDisplay = ({ projectSections }) => {
  const { setFallbackImageSource } = useFallbackImage('');

  return (
    <Wrapper>
      {projectSections.map((projectSection, index) => (
        <ProjectSectionStyled
          {...getStyledComponentProps(projectSection)}
          key={index}
          isTripleTextEditor={isTripleTextEditor(projectSection)}
          isImageOnly={isImageOnly(projectSection)}
          hasImageAndText={hasImage(projectSection) && !isImageOnly(projectSection)}
          isSingleTextEditor={isSingleTextEditor(projectSection)}
          isImageGallery={isImageGallery(projectSection)}
          imageGalleryCount={isImageGallery(projectSection) && projectSection.text.length}
        >
          {!isImageOnly(projectSection) && (
            <div className="project-section__text">
              <div className="project-section__text__wrapper">
                <TextElement text={getTextEditorValue(projectSection, 0)} />
              </div>
              {isTripleTextEditor(projectSection) && (
                <>
                  <div className="project-section__text__wrapper">
                    <TextElement text={getTextEditorValue(projectSection, 1)} />
                  </div>
                  <div className="project-section__text__wrapper">
                    <TextElement text={getTextEditorValue(projectSection, 2)} />
                  </div>
                </>
              )}
            </div>
          )}
          {hasImage(projectSection) && (
            <>
              <div className="project-section__image__wrapper">
                <ImageLoader
                  src={`${base}/images/${projectSection.imagePath.folder}/${projectSection.imagePath.image}`}
                  alt={projectSection.imagePath.image}
                  onError={setFallbackImageSource}
                />
              </div>
              {isImageGallery(projectSection) && projectSection.text && projectSection.text.map && (
                <>
                  {projectSection.text.map((textImage, index) => {
                    return (
                      <div className="project-section__image__wrapper" key={index}>
                        <ImageLoader
                          src={`${base}/images/${textImage.folder}/${textImage.image}`}
                          alt={textImage.image}
                          onError={setFallbackImageSource}
                        />
                      </div>
                    );
                  })}
                </>
              )}
            </>
          )}
        </ProjectSectionStyled>
      ))}
    </Wrapper>
  );
};

export default ProjectDetailsDisplay;
